<template>
  <div class="text-left">
    <TopBar
      :breadcrumbItems="breadcrumbItems"
      :button="button"
      permission="v1.agenda.create"
    />

    <DataTable
      :value="data"
      dataKey="_id"
      :rowHover="true"
      class="apin-data-table p-mt-2"
      v-model:selection="selected"
    >
      <template #header>
        <div class="table-header">{{ i18n.$t('List of Information') }}</div>
      </template>
      <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
      <Column field="name" :header="i18n.$t('Name')"></Column>
      <Column field="document.type" :header="i18n.$t('Type')"></Column>
      <Column field="_action" :header="i18n.$t('Action')"></Column>
      <Column :header="i18n.$t('Updated At')">
        <template #body="slotProps">
          <span>{{ displayDate(slotProps.data.updated_at) }}</span>
        </template>
      </Column>
      <Column field="_status" :header="i18n.$t('Status')"></Column>
      <Column :exportable="false">
        <template #body="slotProps">
          <Button
            icon="pi pi-eye"
            class="p-button-rounded p-button-success p-mr-1"
            v-if="can('v1.information.view')"
            @click="
              $router.push({
                path: '/information/view',
                query: { information: slotProps.data._id }
              })
            "
          />
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-1"
            v-if="
              slotProps.data._status === 'New' && can('v1.information.update')
            "
            @click="
              $router.push({
                path: '/information/edit',
                query: { information: slotProps.data._id }
              })
            "
          />
          <Button
            v-if="slotProps.data._status === 'Sent'"
            icon="pi pi-bell"
            class="p-button-rounded p-button-warning p-mr-1"
            @click="
              $router.push({
                path: '/information/notification',
                query: { information: slotProps.data._id }
              })
            "
          />
          <Button
            v-if="slotProps.data._status === 'New'"
            icon="pi pi-angle-double-right"
            class="p-button-rounded p-mr-1"
            @click="sendNotification(slotProps.data)"
          />
          <ButtonConfirm
            @accept="onDelete(slotProps.data._id)"
            v-if="can('v1.information.delete')"
          >
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger"
            />
          </ButtonConfirm>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { formatDate } from '../../plugins/utility';
import useI18n from '@/plugins/useI18n';
import {
  FETCH_INFORMATIONS,
  GET_INFORMATIONS,
  SET_INFORMATION_2_NOTIFICATION,
  GET_USERDATA
} from '../../store/types';
import TopBar from '@/components/Utilities/TopBarBreadCrumb.vue';
import { API } from '@/plugins/api';
import moment from 'moment';
export default {
  data: () => ({
    breadcrumbItems: [{ label: 'Information list', to: '/information/list' }],
    button: {
      title: 'Add Information',
      link: 'information-add'
    }
  }),
  components: {
    TopBar
  },
  setup() {
    const { i18n } = useI18n();
    const router = useRouter();
    const store = useStore();
    const data = computed(() => store.getters[GET_INFORMATIONS]);
    const userData = computed(() => store.getters[GET_USERDATA]);

    const selected = ref([]);

    store.dispatch(FETCH_INFORMATIONS, userData.value.id);

    function displayDate(stamp) {
      return moment(stamp * 1000).format(i18n.$t('date_format'));
    }

    async function sendNotification(item) {
      await store.commit(SET_INFORMATION_2_NOTIFICATION, item);
      router.push({
        path: `/information/send-notification`,
        query: { information: item._id }
      });
    }

    const api = new API();
    function onDelete(id) {
      api.delete('information', id, FETCH_INFORMATIONS);
    }
    return {
      i18n,
      data,
      selected,
      displayDate,
      sendNotification,
      onDelete
    };
  }
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}
td {
  padding: 5px;
}
</style>
